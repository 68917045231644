import "./Home.css";
import Copy from "./Copy/Copy";

function Home() {
  return (
    <div className="Home">
      <Copy />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="78"
        height="63"
        viewBox="0 0 78 63"
        fill="none"
      >
        <path
          opacity="0.687291"
          d="M1 62C13.2694 0.62985 78.3249 28.0155 30.2067 13.2626C65.8747 54.7164 65.6903 25.2931 34.0053 22.2694C46.0159 4.64044 6.95163 -19.9103 76 34.7268C17.3296 59.8882 2.32386 57.8503 49.554 15.5097C65.2829 19.4767 67.3319 42.0656 69.6984 42.6797C2.42891 32.1889 21.996 15.5105 64.5997 20.3184"
          stroke="#1E1E1E"
          stroke-width="1.5"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1722"
        height="1697"
        fill="none"
        className="shapeBG"
        style={{
          zIndex: -1,
        }}
      >
        <g filter="url(#filter0_f_65_13)">
          <path
            d="M1257.36 448.155C1273.19 437 1290.22 426.379 1305.45 414.079C1308.16 445.867 1316.06 477.204 1318.29 509.088C1327.48 639.963 1341.76 770.242 1353.86 900.944C1357.43 939.474 1365 977.21 1369.79 1015.54C1373.68 1046.61 1371.07 1079.26 1371.07 1110.51C1371.07 1170.06 1368.41 1234.96 1348.25 1291.65C1340.91 1312.31 1334.44 1333.1 1312.94 1343.02C1303.31 1347.47 1289.53 1346.74 1279.16 1345.7C1263.92 1344.18 1248.92 1340.21 1234.42 1335.38C1220.87 1330.86 1208.27 1321.38 1194.39 1318.29C1186.35 1316.51 1180.03 1309.9 1172.72 1306.31C1157.87 1299.03 1143.57 1290.91 1128.36 1284.39C1106.91 1275.2 1085.36 1266.5 1064.11 1256.85C1051.24 1251.01 1037.27 1248.19 1024.59 1242.06C1014.69 1237.28 1005.41 1231.36 995.275 1227.02C945.141 1205.54 896.385 1181.78 845.62 1161.76C829.614 1155.44 812.048 1146.06 797.435 1137.03C784.096 1128.78 769.108 1123.78 755.623 1115.87C731.613 1101.77 705.295 1092.1 680.413 1079.66C653.046 1065.98 626.972 1050.1 600.614 1034.79C573.204 1018.88 544.223 1004.58 517.501 987.881C498.225 975.834 476.865 965.743 456.441 955.758C432.983 944.289 411.384 930.049 389.007 916.623C372.555 906.752 350 892.53 350 870.605C350 844.186 352.295 817.82 352.295 791.571C352.295 775.597 354.309 758.94 362.747 745.68C385.489 709.944 432.66 700.795 466.129 679.139C488.932 664.383 516.271 654.01 541.721 644.848C572.675 633.704 601.534 624.847 634.268 620.755C684.16 614.519 733.296 613.872 783.667 613.872C816.419 613.872 849.533 614.787 882.078 611.067C912.213 607.623 939.983 598.082 969.525 592.711C984.215 590.04 997.829 584.126 1011.85 579.454C1029.92 573.429 1049.6 569.999 1066.41 560.587C1097.53 543.156 1131.61 531.16 1162.27 512.912C1195.41 493.182 1225.83 470.368 1257.36 448.155Z"
            fill="#6d76ed"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_65_13"
            x="0"
            y="0"
            width="1721.93"
            height="1696.49"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="175"
              result="effect1_foregroundBlur_65_13"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default Home;
